.videos {
    display: flex;
    flex-wrap:  wrap;
    margin-top: 20px;
    }
    .videos .vid,
    .videos .med {
    flex-basis: 31%;
    margin-bottom: 10px;
    border-radius: 4px;
    }
    .videos .vid {
    width: 93px;
    height: 93px;
    background-size: cover;
    margin-right: 10px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    }
    .videos .vid:nth-child(3n) {
    margin-right: 0;
    }
    .videos .vid span {
    display: none;
    text-transform: capitalize;
    z-index: 2;
    }

     .videos .vid::after {
    content: '';
    width: 20%;
    height: 100%;
    transition: opacity .1s ease-in;
    border-radius: 4px;
    opacity: 0;
    position: absolute;
    }
    .videos .vid:hover::after {
    display: block;
    background-color: #000;
    opacity: .5;
    }

    .videos .vid:hover span {
    display: block;
    background-color: #000;
    color: #fff;
    opacity: .5;
    }
    .videos .med {
    background-color: #c8d8cd;
    align-self: center;
    text-align: center;
    padding: 40px 0;
    text-transform: uppercase;
    color: #848EA1;
    font-size: 12px;
    cursor: pointer;
    }